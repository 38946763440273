import { Helmet } from 'react-helmet'

import * as React from 'react'
import Layout from '../components/Layout'

import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import FAQs from '../components/FAQs'

import { Box, Flex } from 'grid-styled'
import {
  HomeHeader,
  FormContainer,
  AlternatingContent,
  AboutContact,
  VideoContainer,
  info,
} from '.'
import Form from '../components/Form'
import {
  Text,
  GallerySection,
  Content,
  MainContent,
} from '../components/styles'
import Button from '../components/Button'
import ContentCard from '../components/ContentCard'
import Hero from '../components/Hero'
import { Video } from '../components/procedures'
import GatsbyImage from 'gatsby-image'
import GatsbyLink from 'gatsby-link'
import CTA from '../components/CTA'
import BrochureForm from '../components/BrochureForm'
import styled from 'styled-components'

const fireInfo = [
  {
    question: 'Most blogs and podcasts aren’t produced by qualified advisers.',
    answer: `Most blogs and podcasts aren’t produced by qualified advisers. In Australia it’s illegal to give financial advice or recommend financial products without an Australian Financial Services License. The advice you receive isn’t from a professional who understands every aspect of financial planning that is needed to give effective advice. You see a mechanic to fix your car, a solicitor for legal advice, so why do people take financial advice from strangers at a BBQ and free blogs? Would you design your dream home after watching a 10-minute YouTube video on architecture?`,
  },
  {
    question: 'You will miss crucial aspects of FIRE.',
    answer: `A blog might tell you to save and that your Super should have low fees, but it will miss a crucial aspect of your situation that will cost you tens of thousands over the years. A podcast can’t tell you whether to accept the share offer package given by your company, it can’t tell you how to best refinance your loan to save the most interest, it can’t tell you what insurance you need when you transition from the work force, it can’t structure your investments to be tax effective when you take a year off work, it can’t tell you how to best deal with the $10,000 emergency loan that you need to give your children, it can’t tell you how to set up an estate to distribute your wealth to your family, it can’t tell you how to pay less CGT on your upcoming investment property sale, it can’t tell you how to best dissolve your current business partnership, it can’t tell you how to use margin lending to reduce your tax and build your wealth faster and it can’t tell you how to arrange your Australian tax affairs when you decide to work online and live in Thailand.`,
  },
  {
    question: "Don't underestimate efficiency and accountability.",
    answer: `Efficiency and accountability. A lot of the “basics are covered” through personal finance reading and you can make some positive changes yourself. You might be able to get your head around the basics after a year of regular reading in your spare time and start to implement those changes. You make some changes and are budgeting better, paying less interest on your home loan and have your investments making a good return. But it took you 18 months to understand and implement those basic changes. Working with a planner those changes are implemented immediately and when you extrapolate the savings over time you are tens of thousands ahead. Also, people lose focus. A planner will keep you accountable and held to the highest standard.`,
  },
  {
    question: "Let's see the difference.",
    answer: `Imagine you have $100,000 ready to invest and hold it in cash for 1 year making 3%. After a year you learn how to invest, and the money grows to $228,635 over the next 9 years. 

    Now imagine you come to a financial planner from day one. You gain 9% from day one. 
    10 years later you have $245,236. 
    
    You paid the planner $3,000 for the advice and gained $16,601. Net gain $13,601 or 553%. 
    
    Imagine you begin reading the “No Shoes” investor book and begin working through the chapters. Your budget is in check, your home loan is fixed but are suddenly seriously injured and are unable to work. You aren’t properly insured and need to redraw on your home loan for 8 months while you recover and look for another job. 
    Now imagine you paid a planner and the proper insurances were put into place. You don’t need to redraw on the home loan because you got paid out $300,000 from Trauma insurance or your income protection policy kicked in. 
    You paid the planner $6,000 for budgeting, super and insurance advice. Net gain $294,000. These are two simple examples of hundreds of the tangible value a good planner can provide for you. 
    `,
  },
  {
    question: "Static plans don't work well in reality.",
    answer: `Financial planners are personal trainers for your finances. Having a full-time personal trainer working with you each week will allow you to adapt as your situation changes. All your questions will be answered as they arise, and any necessary changes made instantly. This constant monitoring and adapting paired with accountability and progress tracking yields phenomenal results.  `,
  },
]

const Subtitle = styled.h4`
  font-weight: 400;
  max-width: 800px;
  margin: 50px auto;
`

const HomeHeaderNew = styled(HomeHeader)`
  @media (min-width: 900px) {
    justify-content: flex-end;
    padding-right: 140px;
  }
`

class Comp extends React.Component {
  render() {
    const { data } = this.props
    return (
      <Layout header="light">
        <Helmet>
          <title>
            F.I.R.E - Financial Independence - Parinity Financial Professionals
          </title>
          <meta
            name="description"
            content="Parinity F.I.R.E. is a service offered for those whose financial goals could be classed as alternative. Goals centred around the idea of retiring earlier than usual. "
          />
        </Helmet>

        <HomeHeaderNew slant={false} height="75vh">
          <figure>
            <Img fluid={data.jpBackground.childImageSharp.fluid} />
          </figure>
          <div>
            <h2>Parinity F.I.R.E</h2>
            <Text size="large">
              FIRE is about buying your time back and choosing freedom sooner
              rather than more money later.
            </Text>
            <Link to="/contact">
              <Button outline={true} color="#fff">
                Contact us
              </Button>
            </Link>
          </div>
          {/* <FormContainer>
            <div>
              <Form title="Contact us today" showMessage={true} />
            </div>
          </FormContainer> */}
        </HomeHeaderNew>

        <Content style={{ paddingTop: 50 }}>
          <h1>financial independence, retire early</h1>

          <Img
            style={{ borderRadius: 8, margin: '30px 0' }}
            fluid={data.adamFire.childImageSharp.fluid}
          />

          <span>
            There are many versions of F.I.R.E but all have a central theme. You
            want to create passive income so you aren’t working to live.
          </span>

          <h4>About Adam</h4>

          <p>
            Hey there, I’m Adam and I’m one of the advisors at Parinity and the
            head of Parinity F.I.R.E.
          </p>
          <p>
            Parinity F.I.R.E gives you the option to retire early using a
            qualified licenced financial planner. A comprehensive plan will be
            made to cover all areas of your life. Superannuation, Self Managed
            Super, investing, budgeting, tax, loans, debt management, estate
            planning, wills and power of attorney. Australians use professional
            services for legal, tax and health issues. So why do they rely on
            podcasts and anonymous blogs for the most impactful service of their
            life? Gaining financial independence. Parinity F.I.R.E bridges that
            gap.
          </p>
          <p>
            I was born and raised an average Australian and have never earned
            more than the average income. By applying my skillset as a financial
            planner, I now have a life that makes others say, “Lucky you”.{' '}
          </p>
          <p>
            I’m currently based in Thailand where I live in my paid for
            apartment, I travel to a different country 3 to 4 times a year and
            take weekend getaways every month.{' '}
          </p>
          <p>
            If I lost all of my income streams tomorrow, I would be able to live
            for 3 months with all my expenses paid.{' '}
          </p>
          <p>
            I only need to work 10 hours a week to support myself, but I choose
            to work 20 to 30 hours over 4 days so that I can invest and retire
            early.{' '}
          </p>
          <p>
            I plan to semi-retire in 5 years. Semi meaning, I will drastically
            reduce my workload and work more freelance based rather than
            ‘clocking in’ every Monday.
          </p>
          <p>
            I believe work is a means to an end only and the goal of financial
            planning is financial independence. That is to create passive income
            that matches your expenses: creating a situation where work is an
            option rather than a requirement.{' '}
          </p>
          <p>
            As a planner I like to work with the knowns, that is what you
            currently have and make plans based on that. I don’t make plans
            based on future income or speculative property gains. I
            predominately use stocks as my investment vehicle as I believe
            Australian’s over confidence and reliance in property creates the
            common situation of “Equity rich, cash poor”. Cash flow is crucial
            to being wealthy and to exclude it to me is nonsense.{' '}
          </p>

          <p>My current and past clients have achieved the following: </p>
          <ul>
            <li>
              One couple paid off their home loan in 3 years at age 36 and have
              begun investing to retire at 45.{' '}
            </li>
            <li>
              One animator built a portfolio that sustained him travelling the
              world for 3 months of the year.{' '}
            </li>
            <li>
              One average income earner saved half her pay and built a $100,000
              portfolio in 2 years.
            </li>
            <li>
              One Fly In Fly Out worker saved 50% of their take home pay
              consistently for one year.
            </li>
            <li>
              One Aussie mum became a millionaire never earning more than
              $60,000 gross income.{' '}
            </li>
          </ul>
          <Button
            onClick={(e) => {
              e.preventDefault()
              Calendly.showPopupWidget('https://calendly.com/lancecheung/')
            }}
            inverted={true}
          >
            Book an appointment now!
          </Button>
        </Content>

        <Hero bgImage={data.blurredHero.childImageSharp} height="550px">
          <h3>want to choose freedom?</h3>
          <p>
            “Work is a means to an end only. While I enjoy my work and am
            passionate about helping people with their finances, I want to have
            the option to work on my own terms. I don’t want a million-dollar
            portfolio, I want to buy my time back”.
          </p>
          <Flex>
            <Button
              onClick={(e) => {
                e.preventDefault()
                Calendly.showPopupWidget('https://calendly.com/lancecheung/')
              }}
              color={'#fff'}
              small={true}
              outline={true}
            >
              Book an appointment online
            </Button>
          </Flex>
        </Hero>

        <Content style={{ paddingTop: 50 }}>
          <h1>What is F.I.R.E. and is it for you?</h1>
          <span>
            Parinity F.I.R.E. is a service offered for those whose financial
            goals could be classed as alternative. Goals centred around the idea
            of retiring earlier than usual. The key letter in FIRE is E: early.
            You want to retire earlier than most. That is, not at 60 or 65.
            Maybe 55, 45, or even as early as 35.
          </span>
          <Img
            style={{ borderRadius: 8, margin: '30px 0' }}
            fluid={data.adamBike.childImageSharp.fluid}
          />
          <h4>Buying back your freedom</h4>
          <p>
            The traditional structure of financial planning may not suit someone
            who wants to retire early. A structure that involves: salary
            sacrificing into super for the tax benefits, redrawing on your home
            equity to buy an investment property, paying thousands each year in
            insurance to service your high debt level years, planning one big
            holiday each year, working full time for the next 20 to 30 years and
            retiring with millions at 60 and hoping you’re in good health.
          </p>
          <h4>3 examples of applying F.I.R.E are:</h4>
          <ul>
            <li>
              <strong>Semi-retirement.</strong> You have your basic expenses
              covered and a healthy emergency fund. You only work for your extra
              fun and travel money. This could mean doing contract work for 2
              months then travelling for 3. Working at a café and quitting the
              day the boss annoys you. Sitting at the beach all day reading
              while trying to make your hobby into a small side income.{' '}
            </li>
            <li>
              <strong>Lean early retirement.</strong> A minimalist lifestyle
              with all expenses covered. You have no need to work and have a set
              budget each year for travelling and hobbies. Your time is your own
              but your life is based around free or low cost activities: riding
              your bike, surfing, hiking, playing games or volunteering at the
              local dog shelter.{' '}
            </li>
            <li>
              <strong>Early retirement.</strong> You have no need to work and
              your life includes unnecessary luxuries. Overseas trips are paid
              for, new vehicles and eating out at that nice steak house aren’t
              uncommon. This is the image most of us associate with the word
              retirement.{' '}
            </li>
          </ul>
          <span>
            “The lower your number the faster we get there. The idea is to cut
            away the fluff while still remaining happy. I could retire tomorrow
            if all I wanted was to watch Netflix every day and eat basic rice
            dishes”.
          </span>
          <p>
            This number you need to be happy is different for everyone. Once we
            establish that number, we aim to build up that income passively
            through investments. Once we have built our investments, we start
            our new life doing what we want. Sleep, travel, spend time with
            family, drive around Australia, spend a month in Cambodia, work or
            all of the above.{' '}
          </p>
        </Content>

        <AlternatingContent>
          <h2>How much and why should you use Parinity?</h2>
          <Subtitle>
            The best analogy I’ve ever heard of financial planning is someone
            sailing a ship for 6 months before calling to ask for help. The
            captain receives a call from someone needing help to reach their
            destination. The advice given is “You should have steered east 6
            months ago”.
          </Subtitle>
          <ContentCard
            image={data.patiosBrisbane.childImageSharp}
            title="financial foundations meeting"
            text={
              <>
                <span>
                  Think of this meeting like asking someone for directions at
                  the jetty before you sail off. “Go this way” will help you
                  more than you sailing blindly, but it doesn’t allow for
                  adjustment and corrections along the way. A 90-minute chat to
                  discuss your plans and look at your situation objectively.
                  <ul>
                    <li>How does FIRE work and is it for you? </li>
                    <li>
                      Is FIRE possible for you based on your current income and
                      spending habits?{' '}
                    </li>
                    <li>Are you on the right path to achieve your goals? </li>
                    <li>Some overall ideas on how to lower your expenses.</li>
                  </ul>
                </span>

                <span>Total cost: $395 inc GST</span>
              </>
            }
            buttons={[
              {
                to: '/contact',
                text: 'Book appointment',
              },
            ]}
          />
          <ContentCard
            image={data.training.childImageSharp}
            title="project advice"
            text={
              <>
                <span>
                  This is an actual map for your sailing trip with marked
                  co-ordinates. You are given a steering wheel but it’s up to
                  you to use it. We tell you exactly how to retire early, give
                  you the budget, set up your investments and give you the tools
                  to implement and track it yourself.
                  <ul>
                    <li>Creation of your initial financial plan.</li>
                    <li>
                      Implementation of any recommended financial products
                    </li>
                    <li>Formal Statement of Advice. </li>
                  </ul>
                </span>
                <span>Project costs: $1,650 - $11,000 inc GST</span>
              </>
            }
          />
          <ContentCard
            image={data.offField.childImageSharp}
            title="parinity partnership program"
            text={
              <>
                <span>
                  This is your personal, on-board captain option. We start with
                  an initial "project advice" consultation.
                </span>

                <span>
                  This program is the most intensive and yields the best
                  results. Monthly check-ins ensure you are sticking to the plan
                  and catch any issues before they do any real damage. You are
                  kept focussed and the plan is followed through as life
                  happens.
                </span>

                <span>
                  Contact us today to hear about our past client results.
                </span>

                <span>Total cost: $550/month - $5,500/month inc GST</span>
              </>
            }
          />
        </AlternatingContent>

        <FAQs
          flushTop={true}
          faqs={fireInfo}
          title="Why don’t I just read the online blogs if I want to retire early?"
          button={true}
          background={data.whyUs.childImageSharp.fluid.src}
        />

        <AboutContact />
      </Layout>
    )
  }
}
export default Comp

export const query = graphql`
  query FireQuery {
    jpBackground: file(relativePath: { eq: "fire-bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    adamFire: file(relativePath: { eq: "adam-fire.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    adamBike: file(relativePath: { eq: "motorbike.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    specialists: file(relativePath: { eq: "specialists.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    patiosBrisbane: file(relativePath: { eq: "meeting.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    temp: file(relativePath: { eq: "parinity-video-temp.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    training: file(relativePath: { eq: "beach-chill.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    offField: file(relativePath: { eq: "adam-outlook.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    registrars: file(relativePath: { eq: "registrars.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    whyUs: file(relativePath: { eq: "why-us-jp.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3000, quality: 95) {
          ...GatsbyImageSharpFluid
        }
      }
    }

    accessories: file(relativePath: { eq: "roofing-brisbane.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    blurredHero: file(relativePath: { eq: "partnership-2.jpg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    graphic: file(relativePath: { eq: "personal-graphic.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    gallery: allFile(filter: { relativeDirectory: { eq: "gallery/patios" } }) {
      edges {
        node {
          childImageSharp {
            fixed(width: 400, height: 400, toFormat: JPG) {
              ...GatsbyImageSharpFixed
            }
            modal: fluid(maxWidth: 1200, quality: 98, toFormat: JPG) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
